
// const HOST_LOCAL = 'http://localhost:1337';
const HOST = 'https://prod-backendeditor.hokmok.com';

let obj = {
    HOST : HOST,//_LOCAL,
    NONE_CONNECT_UGS: false,
}

module.exports = obj;
